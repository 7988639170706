var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"0"},attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{staticClass:"bg-white",attrs:{"title":_vm.$t('common.search'),"color":"green","flat":"","full-width":""}},[_c('v-form',[_c('v-container',{attrs:{"py-0":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md3":""}},[_c('v-select',{attrs:{"items":_vm.bannerStatusList,"label":_vm.$t('evoucher.issuance.status')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.text))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.text))+" ")]}}]),model:{value:(_vm.bannerStatusSelected),callback:function ($$v) {_vm.bannerStatusSelected=$$v},expression:"bannerStatusSelected"}})],1)],1),_c('v-btn',{staticClass:"pull-right",staticStyle:{"margin-right":"0"},attrs:{"color":"success"},on:{"click":_vm.onSearch}},[_c('span',{staticClass:"btn-label mr-3"},[_c('i',{staticClass:"glyphicon glyphicon-search"})]),_c('span',{staticStyle:{"text-transform":"none"}},[_vm._v(_vm._s(_vm.$t("common.search")))])])],1)],1)],1),_c('material-card',{attrs:{"title":_vm.$t('portal.banner.adsList'),"color":"green","flat":"","full-width":""}},[[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.onShowModalAddBannerSlider(null)}}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")])],_c('v-data-table',{attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.advertisementHeader,"items":_vm.advertisementInfoList,"single-select":true,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.stt))]),_c('td',[_c('img',{staticStyle:{"border":"2px solid #fff"},attrs:{"src":item.imageUrl ? item.imageUrl : _vm.noImg,"width":"60","height":"40"}})]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',{staticStyle:{"max-width":"250px"}},[_vm._v(_vm._s(item.description))]),_c('td',{staticStyle:{"max-width":"250px"}},[_c('span',{style:(("padding: 5px; background-color: " + (item.status == _vm.bannerStatus.PREPARE ? '#ffa21a' : (item.status == _vm.bannerStatus.ACTIVE ? '#4caf50' : '#f44336')) + "; color: #fff; border-radius: 5px;"))},[_vm._v(_vm._s(_vm.$t(_vm.getStatus(item.status))))])]),_c('td',{staticClass:"text-xs-center"},[_c('v-menu',{attrs:{"offset-y":"","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"indigo","dark":""}},on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true),model:{value:(item.selectedButton),callback:function ($$v) {_vm.$set(item, "selectedButton", $$v)},expression:"item.selectedButton"}},[_c('v-card',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"warning"},on:{"click":function($event){(item.status == _vm.bannerStatus.PREPARE || item.status == _vm.bannerStatus.CLOSE) ? _vm.onShowModalPublicBanner(item) : _vm.onShowModalInactiveBanner(item)}}},on),[((item.status == _vm.bannerStatus.PREPARE || item.status == _vm.bannerStatus.CLOSE))?_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-check-underline")]):(item.status != _vm.bannerStatus.PREPARE && item.status != _vm.bannerStatus.CLOSE)?_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close-outline")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s((item.status == _vm.bannerStatus.PREPARE || item.status == _vm.bannerStatus.CLOSE) ? _vm.$t('advertisementInfo.status.public') : _vm.$t('advertisementInfo.status.inactive')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onShowModalAddBannerSlider(item.id)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.update')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.onShowModalDeleteBannerSlider(item.id)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])])],1)],1)],1)]}}])})],2)],1)],1),_c('pagination',{attrs:{"total":_vm.advertisementPaginate.totalPage,"current-page":_vm.advertisementPaginate.currentPage,"row-per-page":_vm.advertisementPaginate.rowPerPage},on:{"onPageChange":_vm.onPageChange}}),_c('addBannerSliderModal',{ref:"modalBannerSlider",on:{"addBannerSliderSuccess":_vm.onAddBannerSliderSuccess}}),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})}),_c('confirm-modal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":function($event){_vm.actionSelected == _vm.actionType.PREPARE ? _vm.onInactiveBanner() : (_vm.actionSelected == _vm.actionType.ACTIVE ? _vm.onPublicBanner() : _vm.onDeleteBannerSlider())}}}),_c('loadingBar',{attrs:{"is-loading":_vm.isLoading}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }