<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-bototm: 0; padding-left: 0; padding-right: 0;">
    <v-layout wrap>
      <v-flex md12>
        <div class="ml-4 mb-2">
          <tab-header
            :tab-items="tabItems"
            @onSelectTab="onSelectTab" />
        </div>
        <bannerSlider v-if="tabSelected == tabs.BANNER_SLIDER" />
        <logoBgMenu v-if="tabSelected == tabs.LOGO_BACKGROUND" />
        <bannerAds v-if="tabSelected == tabs.BANNER_MIDDLE" />
        <bannerVerical v-if="tabSelected == tabs.BANNER_VERTICAL" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BannerSlider from 'Views/Portal/BannerSlider/List'
import LogoBgMenu from 'Views/Portal/Config/LogoBgMenu'
import BannerAds from 'Views/Portal/BannerAds/List'
import BannerVerical from 'Views/Portal/BannerVertical/List'
import TabHeader from 'Components/TabHeader.vue'
export default {
  components: {
    BannerSlider,
    LogoBgMenu,
    BannerAds,
    BannerVerical,
    TabHeader
  },
  data: () => ({
    tabs: {
      LOGO_BACKGROUND: 1,
      BANNER_SLIDER: 2,
      BANNER_MIDDLE: 3,
      BANNER_VERTICAL: 4
    },
    tabItems: [
      {
        title: 'portal.banner.logoAndBgMenu',
        id: 1
      },
      {
        title: 'portal.banner.slider',
        id: 2
      },
      {
        title: 'portal.banner.ads',
        id: 3
      },
      {
        title: 'portal.banner.vertical',
        id: 4
      }
    ],
    tabSelected: 1
  }),
  methods: {
    /**
     * Select tab
     */
    onSelectTab: function ({ index, item }) {
      this.tabSelected = item.id
    }
  }
}
</script>

<style lang="scss">
</style>
